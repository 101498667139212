import { AfterContentChecked, ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { WebsiteService } from '../services/website.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidateEmailService } from '../validate-email.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

export interface User {
  firstname: string;
  lastname: string;
  email: string;
  company: string;
  message?: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [
    './home.component.css'
  ]
})

export class HomeComponent implements AfterContentChecked {
  @ViewChild('content') tmpl: TemplateRef<any>;
  formSubmitted = false;
  whitepaperForm: FormGroup;
  isLoading = false;
  slides: string[] = [
    'one',
    'two',
    'three',
    'four'
  ];
  constructor(formBuilder: FormBuilder, private service: WebsiteService, private modalService: NgbModal, private cdRef: ChangeDetectorRef) {
    this.whitepaperForm = formBuilder.group({
      firstname: '',
      lastname: '',
      email: '',
      company: '',
    });
    this.service = service;
  }

  ngAfterContentChecked(): void {
    // this.view.createEmbeddedView(this.tmpl);
    this.cdRef.detectChanges();
  }

  validateEmail(): void {
    const validMail = new ValidateEmailService().validate(this.whitepaperForm.controls.email.value);
    if (!validMail) {
      this.whitepaperForm.controls.email.setErrors({ valid: false });
    } else {
      this.whitepaperForm.controls.email.setErrors(null);
    }
  }

  openModal(content): void {
    // tslint:disable-next-line:no-unused-expression
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result;
  }

  sendWhitepaper(): void {
    this.isLoading = true;
    this.service.sendWhitepaper(this.whitepaperForm.value).subscribe(() => {
      this.formSubmitted = true;
      this.isLoading = false;
    });
  }

}
