import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidateEmailService {

  constructor() { }

  validate(email: string): boolean {
    const REGEX_EMAIL = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    const REGEX_COMMERCIAL_EMAIL = /@googlemail\.|@yopmail\.|@gmail\.|@hotmail\.|@yahoo\.|@outlook\.|@example\.|@bol\.|@aol\.|@live\.|@yandex\.|@uol\.|@icloud\.|@me\.|@email\.|@terra\.|@globo\.|@ig\.|@gmx\.|@brturbo\.|@ibest\.|@zipmail\.|@zoho\./;
    return (REGEX_EMAIL.test(email) && !REGEX_COMMERCIAL_EMAIL.test(email));
  }
}
