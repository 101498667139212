<div id="beacons" class="common">
  <section id="banner">
    <div class="item">
      <div class="verticalize">
        <div class="wrapper">
          <h2 style="font-weight: 300">Aurea Smart Beacons</h2>
          <p>Software sofisticado exige os melhores hardwares, homologados e totalmente integrados em nossa
            plataforma. Perfeita sintonia entre software e hardware.</p>
        </div>
      </div>
    </div>
  </section>

  <section id="beacon">
    <div class="wrapper">
      <div class="text">
        <h3>Aurea Smart Beacon 105<sup>©</sup></h3>
        <p>O <strong>Aurea Smart Beacon 105</strong><sup>®</sup> é um beacon Bluetooth<sup>®</sup> SMART independente que inclui software e hardware preparados para utilização na plataforma <strong>Aurea</strong>. Sua construção inclui um compartimento para bateria do tipo moeda CR2477.</p><br/>
        <p>Seu hardware, baseado no módulo de chipset Nordic nRF51822, possui alto desempenho e baixo consumo. Com potência de saída de até +4 dBm e distância de alcance até 50 metros. O equipamento suporta temperaturas entre -20ºC e +80ºC e está em conformidade com os padrões iBeacon<sup>©</sup> e Eddystone™. O equipamento possui completa homologação emitida pela Agência Nacional de Telecomunicações (ANATEL) para utilização em todo território nacional.</p>
        <img src="/assets/img/aureabox-open.png" id="aureabox"/>

      </div>
      <img src="/assets/img/aurea-sb105.png" id="sb105"/>

      <div class="imagerow">
        <img alt="Anatel Logo" src="/assets/img/anatel.png"/>
        <img alt="Eddystone Logo" src="/assets/img/eddystone.png"/>
        <img alt="iBeacon Logo" src="/assets/img/ibeacon.png"/>
        <img alt="Bluetooth Smart Logo" src="/assets/img/bt-smart.png"/>
      </div>
    </div>
  </section>

  <section id="details" class="sb105">
    <div class="wrapper">
      <div class="item">
        <span class="um"></span>
        <h4>Alta Eficiência Energética</h4>
        <p>Nosso firmware otimizado garante um ganho de energia de até 20% quando comparado com beacons comuns do mercado.
        </p>
      </div>

      <div class="item">
        <span class="dois"></span>
        <h4>Segurança</h4>
        <p>Beacons individuais com senhas e configurações distintas garante segurança para sua infraestrutura.</p>
      </div>

      <div class="item">
        <span class="tres"></span>
        <h4>Pronta para Escalar</h4>
        <p>O equipamento dispensa abertura para ligar. Basta destravar a bateria e posicioná-lo através da etiqueta já presente. Simples e rápido.</p>
      </div>

      <div class="item">
        <span class="quatro"></span>
        <h4>Sua Marca</h4>
        <p>Nossos beacons são preparados de fábrica com seu branding e respeitando sua marca. Afinal, você está inovando e o mundo precisa saber!</p>
      </div>
    </div>
  </section>
</div>
