import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {WebsiteService} from '../services/website.service';
import {ValidateEmailService} from '../validate-email.service';
import {throwError} from 'rxjs';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {
  formSubmitted: boolean;
  contactForm: FormGroup;
  isLoading: boolean;

  constructor(formBuilder: FormBuilder, private service: WebsiteService) {
    this.contactForm = formBuilder.group({
      firstname: '',
      lastname: '',
      email: '',
      company: '',
      emailBody: ''
    });
    this.service = service;
  }

  ngOnInit(): void {
    this.formSubmitted = false;
  }

  validateEmail(): void {
    const validMail = new ValidateEmailService().validate(this.contactForm.controls.email.value);
    if (!validMail) {
      this.contactForm.controls.email.setErrors({ valid: false });
    } else {
      this.contactForm.controls.email.setErrors(null);
    }
  }

  sendContactEmail(): void {
    this.isLoading = true;
    this.service.sendContact(this.contactForm.value).subscribe(data => {
      this.formSubmitted = true;
      this.isLoading = false;
    });
  }

}
