<div id="home">
  <header>
    <div class="wrapper">
      <h1>
        <a routerLink="/" alt="Aurea">
          <strong>Aurea</strong>
          <img src="/assets/img/logo.png" alt="Aurea"/>
        </a>
      </h1>

      <nav>
        <ul>
          <li class="parent">
            <a routerLink="/produtos">Produtos</a>
            <ul class="drop">
              <li class="arrow"><span></span></li>
              <li><a routerLink="/produtos" fragment="aureamanager">Aurea Manager</a></li>
              <li><a routerLink="/produtos" fragment="sdk">SDKs e APIs Enterprise</a></li>
              <li><a routerLink="/produtos" fragment="fleetmanager">Aurea Fleet Manager</a></li>
              <li><a routerLink="/beacons">Aurea Smart Beacons</a></li>
            </ul>
          </li>
          <li>
            <a routerLink="/solucoes">Soluções</a>
          </li>
          <li>
            <a routerLink="/parceiros">Parceiros</a>
          </li>
          <li class="parent">
            <a routerLink="/sobre">Sobre</a>
            <ul class="drop">
              <li class="arrow">
                <span></span></li>
              <li><a href="/contato">Contato</a></li>
            </ul>
          </li>
        </ul>
      </nav>

      <a id="mobile-menu" href="#sidr">Toggle menu</a>
      <div id="sidr" style="display:none;">
        <nav>
          <ul>
            <li class="parent">
              <a href="produtos.html">Produtos</a>
              <ul class="drop">
                <li><a routerLink="/produtos" fragment="aureamanager">Aurea Manager</a></li>
                <li><a routerLink="/produtos" fragment="sdk">SDKs e APIs Enterprise</a></li>
                <li><a routerLink="/produtos" fragment="fleetmanager">Aurea Fleet Manager</a></li>
                <li><a routerLink="/beacons">Aurea Smart Beacons</a></li>
              </ul>
            </li>
            <li><a routerLink="/solucoes">Soluções</a></li>
            <li><a routerLink="/parceiros">Parceiros</a></li>
            <li class="parent">
              <a routerLink="/sobre">Sobre</a>
              <ul class="drop">
                <li><a routerLink="/contato">Contato</a></li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
  <router-outlet></router-outlet>
  <footer>
    <div class="wrapper">
      <a routerLink="/" class="logo"><img src="/assets/img/logo.png" alt="Aurea"/></a>
      <div class="bottom">
        <div>
          <div>
            <div style="margin-bottom: 15px; outline: none; cursor: pointer" routerLink="/sobre"><a style="font-weight: 100;" >Sobre</a></div>
            <div style="margin-bottom: 15px; outline: none; cursor: pointer" routerLink="/parceiros"><a style="font-weight: 100;" >Parceiros</a></div>
            <div style="margin-bottom: 15px; outline: none; cursor: pointer" routerLink="/contato"><a style="font-weight: 100;" >Contato</a></div>
          </div>
        </div>
        <div class="right">
          <ul>
            <li><a href="http://linkedin.com/company/aurea" class="linkedin" title="LinkedIn"></a></li>
            <li><a href="https://twitter.com/useaurea" class="twitter" title="Twitter"></a></li>
            <li><a href="https://www.facebook.com/UseAurea" class="facebook" title="Facebook"></a></li>
          </ul>
          <p>© 2023 Aurea Soluções Tecnológicas Ltda. Todos direitos reservados.</p>
        </div>
      </div>
    </div>
  </footer>
</div>
