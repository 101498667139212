<div id="produtos">
  <section id="banner">
    <div class="item">
      <div class="verticalize">
        <div class="wrapper" style="text-align: center; font-weight: 300;">
          <h2>Produtos</h2>
        </div>
      </div>
    </div>
  </section>

  <section id="product">
    <div class="wrapper">
      <div class="text">
        <h3>Aurea Manager<sup>©</sup></h3>
        <p>Totalmente baseado em nuvem, o <strong>Aurea Manager</strong> permite a gerência de infraestrutura (asset
          management), integração de aplicações, especificação de campanhas e analytics. Uma de suas principais
          características é a administração completa da infraestrutura, permitindo uma integração automática entre
          equipamento (e.g. beacons) diretamente da fábrica até seu deployment, sem interferência humana,
          eliminando assim erros e inconsistências, além de acompanhamento remoto das condições da rede.</p>
        <br>
        <p>Suportando os padrões iBeacon<sup>©</sup> e Eddystone™, a plataforma permite sua utilização por times
          multi funcionais, por exemplo em cenários onde marketing e TI compatilham o uso do sistema mas com permissões
          diferenciadas.</p>
      </div>
      <div class="imageright">
        <img class="medium" src="/assets/img/aureamanager-beacon-mgmt.png"/>
      </div>
      <div class="text">
        <h4>Avançado Asset Management</h4>
        <p>A plataforma <strong>AUREA</strong> permite uma completa gerência do parque de instalações de redes de beacons, permitindo o controle remoto de bateria, alertas, atualizações de firmware, acompanhamento de deployments e audit trails.</p>
      </div>
      <div class="text">
        <h4>Multi Aplicação</h4>
        <p>O Aurea Manager integra múltiplas aplicações em paralelo, <strong>tanto aplicações móveis como aplicações
          corporativas</strong>, onde cada aplicação também conta com ambientes distintos de teste e produção. Através de nossa API
          Enterprise é possível rapidamente fazer uso da tecnologia de proximidade, integrada a sistemas corporativos como BI e
          CRM.</p>
        <h4>Avançados Controles de Impacto</h4>
        <p>Nossa preocupação em permitir um controle granular da interação com o cliente sempre foi muito grande.
          Por isso desenvolvemos a solução mais avançada do mercado para que você possa fazer uso de várias regras
          de negócio e somente impactar (ou não) um usuário no momento correto. Seja por distância, por limitação de tempo, por
          perfil, não importa: você está sempre no controle.</p>
      </div>
      <div class="imageright">
        <img class="medium" src="/assets/img/aureamanager-capping.png"/>
      </div>
      <div class="text">
        <h4>Rich Analytics e Insights</h4>
        <p>Nosso modulo de analytics fornece em tempo real informações agregadas e consolidadas sobre presença, independentemente da tecnologia utilizada. Por exemplo, em ambientes utilizando múltiplos equipamentos beacon, uma análise de intensidade de sinal e período de presença é usado para agregar as informações ao longo do tempo.</p>
      </div>
      <div class="imageright">
        <img class="medium" src="/assets/img/aureamanager-dashboard.png"/>
      </div>
    </div>
  </section>

  <section id="details" class="aureamanager">
    <div class="product-container">
      <div class="item item-mobile">
        <span class="um"></span>
        <h4>Máxima Flexibilidade via Tags</h4>
        <p>Nossa solução é a única plataforma da América Latina que utiliza a flexibilidade de tageamento para
          estruturar geofences e beacons, suportando quaisquer cenário de implantação em escala.
        </p>
      </div>

      <div class="item item-mobile">
        <span class="dois"></span>
        <h4>Segurança</h4>
        <p>Nossa plataforma e SDKs utilizam alguns dos algoritmos de segurança mais modernos, através de
          criptografia TLS e assinatura dinâmica de mensagens.</p>
      </div>

      <div class="item item-mobile">
        <span class="tres"></span>
        <h4>Pronta para Escalar</h4>
        <p>A utilização de tecnologias como beacons e geofencing trazem desafios nas aplicação em escala.
          Deployments, asset management, gerência de campanhas em escala.<br>Da importação automática de beacons
          da fábrica até sua gerência no dia-a-dia, o <strong>Aurea Manager</strong> automatiza todo o processo e você se preocupa
          com o que interessa: a experiência do seu cliente.</p>
      </div>

      <div class="item item-mobile">
        <span class="quatro"></span>
        <h4>Analytics e Inteligência</h4>
        <p>Nosso dashboard oferece, em tempo real, analytics, detecções e perfis identificados. Além disso,
          informações de estado da rede, como status de equipamento e aplicativos auxiliam na gerência de sua
          solução.</p>
      </div>
    </div>
  </section>

  <section id="product">
    <div class="wrapper" id="sdk">
      <div class="text">
        <h3>Aurea SDKs e API Enterprise</h3>
        <p>A plataforma <strong>Aurea</strong> oferece SDKs sofisticados para a rápida implementação de marketing de proximidade e
          coleta de inteligência em aplicativos. Atualmente suportando as plataformas Apple iOS, Google Android e Apache Cordova,
          nossos SDKs eliminam toda a complexidade na gerência das atividades da aplicação e permitem aos
          desenvolvedores, em poucos minutos, entregar uma solução de proximidade avançada.</p>
      </div>
      <div id="imageright-mobileos" class="imageright">
        <ul>
          <li><img class="small" src="/assets/img/apple-ios.png"/></li>
          <li><img class="small" src="/assets/img/google-android.png"/></li>
          <li><img class="small" src="/assets/img/apache-cordova.png"/></li>
        </ul>
      </div>
      <div class="text">
        <h4>Integração Enterprise</h4>
        <p>Sabemos que a utilização sofisticada de proximidade é mais poderosa quando sistemas conversam. Foi
          pensando justamente em cenários corporativos onde dados de clientes estão dispersos que desenvolvemos
          nossa API de integração enterprise. Através dela, é possível aliar silos de dados já disponíveis no
          ambiente corporativo (e.g. saldo de um cliente) com nossa plataforma de proximidade. Tudo de maneira
          absolutamente segura onde os dados não deixam seu ambiente corporativo.</p>
        <img src="/assets/img/aurea-integration.png"/>
      </div>
    </div>
  </section>

  <section id="details" class="sdks">
    <div class="product-container">
      <div class="item item-mobile">
        <span class="um"></span>
        <h4>Baixo Consumo</h4>
        <p>A implementação de inteligência de localização em SDKs, quando feita de forma indevida, pode comprometer
          seriamente o consumo de bateria por parte de aplicativos em smartphones. Nossos SDKs são otimizados continuamente para
          não comprometer a bateria de smartphones e assim não prejudicar seus usuários.
        </p>
      </div>

      <div class="item item-mobile">
        <span class="dois"></span>
        <h4>Segurança</h4>
        <p>Nossos SDKs utilizam alguns dos algoritmos de segurança mais modernos, através de criptografia TLS e
          assinatura dinâmica de mensagens.</p>
      </div>

      <div class="item item-mobile">
        <span class="tres"></span>
        <h4>Isolamento de Dados</h4>
        <p>Através de nosso módulo de integração corporativa, dados sigilosos de clientes não deixam seu ambiente
          corporativo.</p>
      </div>

      <div class="item item-mobile">
        <span class="quatro"></span>
        <h4>Documentação Completa</h4>
        <p>Nossos SDKs e Guias de Integração são completamente documentados e testados pelo mercado nas principais
          lojas de aplicativos.</p>
      </div>
    </div>
  </section>

  <section id="product">
    <div class="wrapper" id="fleetmanager">
      <div class="text">
        <h3>Aurea Fleet Manager<sup>©</sup></h3>
        <p>A utilização da tecnologia beacon em escala exige soluções para otimizar todo o processo de instalação, manutenção e suporte de equipes em campo. Você não quer, por exemplo, instalar o hardware errado, mal-configurado ou permitir ainda que indivíduos sem a devida autorização manipulem sua infraestrutura.</p>
        <p>O Aurea Fleet Manager é a primeira solução de campo completa da América Latina para a gerência de infraestrutura e totalmente integrado à plataforma Aurea.</p>
        <a href="https://play.google.com/store/apps/details?id=com.useaurea.fleet&hl=pt&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img id="googleplay" alt="Baixar do Google Play" src="https://play.google.com/intl/en_us/badges/images/generic/pt-br-play-badge.png"/>
        </a>

      </div>
      <div id="imageright-fleetmanager" class="imageright">
        <img class="medium" src="/assets/img/aurea-fleet-manager.png"/>
      </div>
    </div>
  </section>

  <section id="details" class="fleetmanager">
    <div class="product-container">
      <div class="item item-mobile">
        <span class="um"></span>
        <h4>Instalação Rápida em Escala</h4>
        <p>Instalar centenas e milhares de beacons nunca foi tão fácil e rápido. Cada beacon é configurado
          individualmente e de maneira segura em nossa plataforma, permitindo que um técnico em campo rapidamente
          instale equipamentos através do Aurea Fleet Manager, independentemente de sua configuração.
        </p>
      </div>

      <div class="item item-mobile">
        <span class="dois"></span>
        <h4>Segurança</h4>
        <p>Sendo a plataforma autoritativa, a solução controla de maneira segura os beacons e técnicos autorizados
          antes, durante e após as instalações.</p>
      </div>

      <div class="item item-mobile">
        <span class="tres"></span>
        <h4>Over the Air</h4>
        <p>A solução permite a atualização over-the-air e segura de todas as características de sua infraestrutura,
          dispensando a visita presencial de alguém especializado.</p>
      </div>
    </div>
  </section>

</div>
