<div id="contato" class="common">

  <section id="banner">
    <div class="item">
      <div class="verticalize">
        <div class="wrapper">
          <h2>Contato</h2>
        </div>
      </div>
    </div>
  </section>

  <section id="content">
    <div class="wrapper">
      <div class="form-area">
        <h3>É fácil conversar com a gente!</h3>
        <p>Tem dúvidas, sugestões ou perguntas? Interesse em ser parceiro ou cliente? Não importa, por aqui você tem
          um canal direto com a gente.</p>
        <div *ngIf="formSubmitted">
          <h4>Recebemos sua mensagem e em breve retornaremos.</h4>
        </div>
        <form id="contactForm" autocomplete="off" [formGroup]="contactForm" (ngSubmit)="sendContactEmail()" [ngClass]="{ loading: isLoading, submitted: formSubmitted }">
          <ul>
            <li>
              <ul>
                <li>
                  <input formControlName="firstname" name="firstname" autocomplete="given-name" minlength="4" [maxLength]="25" maxlength="25" type="text" placeholder="Primeiro Nome" class="text" required/>
                </li>
                <li>
                  <input formControlName="lastname" name="lastname" autocomplete="family-name" [minLength]="4" [maxLength]="25" maxlength="25" type="text" placeholder="Sobrenome" class="text" required/>
                </li>
                <li>
                  <input formControlName="email" name="email" autocomplete="email" (ngModelChange)="validateEmail()" [minLength]="5" [maxLength]="40" type="email" placeholder="Email Comercial" class="text" required/>
                  <span class="erro" *ngIf="!contactForm.controls.email.valid && contactForm.controls.email.errors && contactForm.controls.email.value != ''">
                    Por favor utilize um email comercial válido.
                  </span>
                </li>
                <li><input formControlName="company" name="company" autocomplete="organization" [minLength]="5" [maxLength]="40" type="text" placeholder="Nome da Empresa" class="text" required/>
                </li>
              </ul>
            </li>
            <li>
              <textarea formControlName="emailBody" name="emailBody" [minLength]="10" [maxLength]="800" [disabled]="isLoading" required></textarea>
              <p *ngIf="contactForm.controls.emailBody.dirty && contactForm.controls.emailBody.errors">Por favor reduza seu texto para até 800 caracteres.</p>
            </li>
            <li><input [disabled]="contactForm.status !== 'VALID'" type="submit" class="submit" value="Enviar"></li>
          </ul>
          <div id="loading"></div>
        </form>
      </div>
      <aside>
        <ul>
          <li>
            <ul>
              <li><h5 itemprop="name">Aurea Tecnologia</h5></li>
              <div itemprop="address">
                <li itemprop="streetAddress">Rua Mostardeiro 777, CJ 1401</li>
                <li><span itemprop="postalCode">90430-001</span> <span itemprop="addressLocality">Porto Alegre</span><span>,&nbsp;</span><span itemprop="addressRegion">RS</span></li>
                <li itemprop="telephone">+55 (51) 3017-1269</li>
              </div>
            </ul>
          </li>
        </ul>
      </aside>
    </div>
  </section>
</div>
