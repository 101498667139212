<section id="banner">
  <carousel cellWidth="100%" height="30%" autoplay="true" loop="true" dots="true" autoplayInterval="3000" transitionDuration="700" arrows pauseOnHover overflowCellsLimit="2" style="z-index: -1">
    <div class="carousel-cell" *ngFor="let img of slides">
      <img class="item {{img}}">
      <div class="verticalize carousel-top">
        <div class="wrapper">
          <h2>Na Hora Certa. No Local Certo.</h2>
          <p>Transforme sua aplicações móveis com a mais avançada e completa tecnologia de proximidade na
            América Latina.</p>
        </div>
      </div>
    </div>
  </carousel>
</section>

<section id="cta">
  <div class="wrapper">
    <h3>Baixe nosso whitepaper sobre <strong>Marketing de Proximidade Móvel</strong> e entenda como nossa tecnologia pode
      beneficiar seu negócio.</h3>
    <button id="whitepaper" (click)="openModal(content)">Baixar Whitepaper</button>
  </div>
</section>

<section id="intro">
  <div class="wrapper">
    <h3>Nossas tecnologias aprimoram o ciclo completo do marketing, permitindo maior compreensão sobre o perfil de seu cliente, entender seu contexto e medir o impacto de campanhas.</h3>
    <section>
      <div class="item item-mobile">
        <span class="um"></span>
        <h4 style="height: 58px;">Audiência Por Proximidade</h4>
        <p>Comunique-se com seus clientes nos momentos e lugares mais relevantes. Nossas avançadas tecnologias de
          proximidade garantem relevância, precisão e respeito aos seus clientes. Além disso, construa
          inteligência de informação para entender e melhor interagir com seu cliente.</p>
      </div>
      <div class="item item-mobile">
        <span class="dois"></span>
        <h4 style="height: 58px;">Campanhas Push Segmentadas e Retargeting</h4>
        <p>Imagine entender e influenciar positivamente o comportamento de seus clientes. Isso tudo usando como base
          o histórico de relacionamento, visitas, horários e preferências de seu cliente. Utilize essa
          inteligência para construir um canal de comunicação móvel personalizado.</p>
      </div>
      <div class="item item-mobile">
        <span class="tres"></span>
        <h4 style="height: 58px;">Rede de Proximidade</h4>
        <p>Comunique-se com seus clientes nos momentos e lugares mais relevantes através de nossa extensa rede de proximidade de beacons e agregação de sinais de presença.</p>
      </div>
    </section>
  </div>
</section>

<section id="clients">
  <div class="wrapper">
    <h3>Nossa Inovação na Mão de Inovadores.</h3>
    <ul class="slides">
      <li class="item">
        <ul>
          <li><img src="/assets/img/clients/ibm.png" alt="IBM Brasil"/></li>
          <li><img src="/assets/img/clients/cielo.png" alt="Cielo"/></li>
          <li><img src="/assets/img/clients/arezzo.png" alt="Arezzo & Co"/></li>
          <li><img src="/assets/img/clients/ogilvy.png" alt="Ogilvy"/></li>
          <li><img src="/assets/img/clients/hering.png" alt="Hering"/></li>
          <li><img src="/assets/img/clients/gigigo.png" alt="Gigigo"/></li>
          <li><img src="/assets/img/clients/multiplan.png" alt="Multiplan"/></li>
          <li><img src="/assets/img/clients/clearchannel.png" alt="Clear Channel"/></li>
          <li><img src="/assets/img/clients/sicredi.png" alt="Sicredi"/></li>
          <li><img src="/assets/img/clients/footballman.png" alt="FootballMan"/></li>
          <li><img src="/assets/img/clients/movistar-estudiantes.png" alt="Movistar Estudiantes"/></li>
        </ul>
      </li>
    </ul>
  </div>
</section>

<section id="segmentos">
  <div class="wrapper">
    <h3>Para todos segmentos de negócio. Incluindo o seu.</h3>
    <p>Localização é um dos pilares para a criação de experiências móveis transformadoras. Líderes em todos os segmentos de negócio então rapidamente compreendendo a importância da utilização de inteligência de localização e contexto como vantagem competitiva.</p>

    <carousel cellWidth="100%" dots="true" autoplay="true" autoplayInterval="8000" loop="true" transitionDuration="700" arrows overflowCellsLimit="0" cellsToShow="1" class="segment-carousel carousel-desktop">
      <div class="carousel-cell" style="display: flex">
        <div class="segmento full-width">
          <span class="um"></span>
          <h4>Out-of-Home</h4>
          <p>Nossa extensa rede de sensores out-of-home e análise de pontos de  presença permitem uma conexão perfeita entre o marketing offline com o online.</p>
        </div>
        <div class="segmento full-width">
          <span class="dois"></span>
          <h4>Fidelidade</h4>
          <p>Explore os milhares de sinais de proximidade e contexto móvel para entender seu cliente e,
            proativamente, ofertar serviços e produtos fidelizados no momento certo.</p>
        </div>
        <div class="segmento full-width">
          <span class="tres"></span>
          <h4>Imobiliário</h4>
          <p>Identifique clientes em busca de imóveis e extraia inteligência no relacionamento e oferta de serviços imobiliários..</p>
        </div>
      </div>
      <div class="carousel-cell" style="display: flex">
        <div class="segmento full-width">
          <span class="quatro"></span>
          <h4>Varejo</h4>
          <p>Dados de localização são críticos na atribuição de campanhas. Extraia inteligência de nossa rede de sensores e dados de presença junto milhares de estabelecimentos comerciais e dezenas de shopping centers. Opcionalmente interaja com seus clientes antes, durante e após a jornada de compras típica do varejo.</p>
        </div>
        <div class="segmento full-width">
          <span class="cinco"></span>
          <h4>Financeiro</h4>
          <p>Explore os milhares de sinais de proximidade e contexto móvel para entender seu cliente e
            proativamente ofertar serviços e produtos financeiros no momento certo.</p>
        </div>
        <div class="segmento full-width">
          <span class="seis"></span>
          <h4>Esportes</h4>
          <p>Construa informação de relacionamento e uma experiência móvel transformadora em arenas, lojas de merchandising, parceiros ou juntamente com patrocinadores.</p>
        </div>
      </div>
    </carousel>

    <carousel cellWidth="100%" dots="true" autoplay="true" autoplayInterval="8000" loop="true" transitionDuration="700" arrows overflowCellsLimit="0" cellsToShow="1" class="segment-carousel carousel-mobile">
      <div class="carousel-cell">
        <div class="segmento full-width">
          <span class="um"></span>
          <h4>Out-of-Home</h4>
          <p>Nossa extensa rede de sensores out-of-home e análise de pontos de  presença permitem uma conexão perfeita entre o marketing offline com o online.</p>
        </div>
      </div>
      <div class="carousel-cell">
        <div class="segmento full-width">
          <span class="dois"></span>
          <h4>Fidelidade</h4>
          <p>Explore os milhares de sinais de proximidade e contexto móvel para entender seu cliente e,
            proativamente, ofertar serviços e produtos fidelizados no momento certo.</p>
        </div>
      </div>
      <div class="carousel-cell">
        <div class="segmento full-width">
          <span class="tres"></span>
          <h4>Imobiliário</h4>
          <p>Identifique clientes em busca de imóveis e extraia inteligência no relacionamento e oferta de serviços imobiliários..</p>
        </div>
      </div>
      <div class="carousel-cell">
        <div class="segmento full-width">
          <span class="quatro"></span>
          <h4>Varejo</h4>
          <p>Dados de localização são críticos na atribuição de campanhas. Extraia inteligência de nossa rede de sensores e dados de presença junto milhares de estabelecimentos comerciais e dezenas de shopping centers. Opcionalmente interaja com seus clientes antes, durante e após a jornada de compras típica do varejo.</p>
        </div>
      </div>
      <div class="carousel-cell">
        <div class="segmento full-width">
          <span class="cinco"></span>
          <h4>Financeiro</h4>
          <p>Explore os milhares de sinais de proximidade e contexto móvel para entender seu cliente e
            proativamente ofertar serviços e produtos financeiros no momento certo.</p>
        </div>
      </div>
      <div class="carousel-cell">
        <div class="segmento full-width">
          <span class="seis"></span>
          <h4>Esportes</h4>
          <p>Construa informação de relacionamento e uma experiência móvel transformadora em arenas, lojas de merchandising, parceiros ou juntamente com patrocinadores.</p>
        </div>
      </div>
    </carousel>
  </div>
</section>

<section id="media">
  <div class="wrapper">
    <h3>Destaques na Mídia</h3>
    <ul class="slides">
      <li class="item">
        <ul>
          <li><a target="_blank" href="http://g1.globo.com/jornal-da-globo/videos/t/edicoes/v/tecnologia-rastreia-localizacao-e-impulsiona-o-interesse-do-consumidor/5375779/" class="logo"><img src="/assets/img/media/jornaldaglobo.png" alt="Jornal da Globo"/></a></li>
          <li><a target="_blank" href="http://g1.globo.com/fantastico/noticia/2017/04/museu-high-tech-criancas-falam-com-obras-de-arte-na-pinacoteca-de-sp.html" class="logo"><img src="/assets/img/media/fantastico.png" alt="Fantástico"/></a></li>
          <li><a target="_blank" href="http://economia.estadao.com.br/blogs/radar-da-propaganda/relogio-de-rua-enviara-anuncios-no-celular/" class="logo"><img src="/assets/img/media/estadao.png" alt="Estadão"/></a></li>
          <li><a target="_blank" href="http://www.meioemensagem.com.br/home/midia/2016/06/10/clear-channel-instala-rede-de-beacons-no-rio.html" class="logo"><img src="/assets/img/media/meioemensagem.png" alt="Meio & Mensagem"/></a></li>
          <li><a target="_blank" href="http://www.channelnetwork.com.br/channelnetwork/Portugues/noticia/index.php?acao=detalhar&cod=903" class="logo"><img src="/assets/img/media/channel-network.png" alt="Channel Network"/></a></li>
        </ul>
      </li>
    </ul>
  </div>
</section>

<section id="tecnologia">
  <div class="wrapper">
    <h3>Solução Enterprise</h3>
    <p>aliada às mais avançadas tecnologias</p>
    <div class="item item-mobile">
      <span class="um"></span>
      <h4>Tecnologia Beacon Enterprise</h4>
      <p>Nossos clientes e parceiros
        sabem que o uso da tecnologia beacons em escala é um desafio sério. Utilizamos beacons avançados de alta qualidade e máxima eficiência de consumo. Por isso. somos líderes na utilização e suporte à tecnologia beacon em escala enterprise (Apple iBeacon e Eddystone), permitindo gerenciar, atualizar e utilizar a tecnologia em grande escala.</p>
    </div>
    <div class="item item-mobile">
      <span class="dois"></span>
      <h4>Perímetros Virtuais Infinitos<br/>(Geofencing)</h4>
      <p>Nossos SDKs exploram o limite que as tecnologias de cerca virtual nos smartphones provêm. Algoritmos
        de última geração analisam esses milhões de pontos de dados e constrõem uma base de inteligência sobre
        seus clientes e seus contextos.</p>
    </div>
    <div class="item item-mobile">
      <span class="tres"></span>
      <h4>SDKs e APIs de Integração Enterprise</h4>
      <p>Nossa tecnologia já foi construída visando integração avançada em aplicativos móveis e de sistemas
        corporativos, como BI e CRM. Algoritmos de segurança de classe mundial são
        utilizados para garantir o máximo de segurança, enquanto nossa infraestrutura escalável garante robustez e alta disponibilidade.</p>
    </div>
  </div>
</section>
<ng-template #content let-modal>
  <div class="modal-header">
    <h3 *ngIf="!formSubmitted">Por favor preencha os campos abaixo para fazer o download de nosso whitepaper.</h3>
    <h4 *ngIf="formSubmitted"> Obrigado {{whitepaperForm.controls.firstname.value}}! Enviamos um email com acesso ao nosso whitepaper. Boa leitura!</h4>
    <button class="close" style="outline: none" (click)="modal.dismiss()">x</button>
  </div>
  <div class="modal-body" style="background-color: #eee" *ngIf="!formSubmitted">
    <div [ngClass]="{ submitted: formSubmitted }">
      <form id="whitepaperForm" autocomplete="off" [formGroup]="whitepaperForm" [ngClass]="{ loading: isLoading, submitted: formSubmitted }">
        <ul>
          <li><input style="margin-bottom: 15px" formControlName="firstname" name="firstname" [minLength]="4" [maxLength]="25" maxlength="25" class="text" placeholder="Nome" required/>
            <span class="erro" *ngIf="whitepaperForm.controls.firstname.value != '' && whitepaperForm.controls.firstname.dirty && whitepaperForm.controls.firstname.errors">Nome inválido!</span>
          </li>
          <li><input style="margin-bottom: 15px" formControlName="lastname" name="lastname" [minLength]="4" [maxLength]="25" maxlength="25" class="text" placeholder="Sobrenome" required/>
            <span class="erro" *ngIf="whitepaperForm.controls.lastname.value != '' && whitepaperForm.controls.lastname.dirty && whitepaperForm.controls.lastname.errors">Nome inválido!</span>
          </li>
          <li><input style="margin-bottom: 15px" formControlName="company" [minLength]="1" [maxLength]="25" maxlength="25" name="company" class="text" placeholder="Empresa" value="" required/>
            <span class="erro" *ngIf="whitepaperForm.controls.company.value != '' && whitepaperForm.controls.company.dirty && whitepaperForm.controls.company.errors">Empresa inválida!</span>
          </li>
          <li>
            <input style="margin-bottom: 15px" formControlName="email" [minLength]="5" [maxLength]="40" type="email" name="email" class="text" (ngModelChange)="validateEmail()" placeholder="Email" value="" required/>
            <span class="erro" *ngIf="whitepaperForm.controls.email.value != '' && whitepaperForm.controls.email.dirty && whitepaperForm.controls.email.errors">
              Por favor utilize um email comercial válido.</span>
          </li>
        </ul>
        <div id="loading"></div>
      </form>
    </div>
  </div>
  <div class="modal-footer" style="justify-content: center" *ngIf="!formSubmitted">
    <div class="bottom">
      <input [disabled]="whitepaperForm.status !== 'VALID'" type="submit" class="submit" (click)="sendWhitepaper()" value="Enviar"/>
    </div>
  </div>
</ng-template>
