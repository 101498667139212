import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {ProdutosComponent} from './produtos/produtos.component';
import {BeaconsComponent} from './beacons/beacons.component';
import {SolucoesComponent} from './solucoes/solucoes.component';
import {ParceirosComponent} from './parceiros/parceiros.component';
import {SobreComponent} from './sobre/sobre.component';
import {ContatoComponent} from './contato/contato.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full',  },
  { path: 'produtos', component: ProdutosComponent },
  { path: 'beacons', component: BeaconsComponent },
  { path: 'solucoes', component: SolucoesComponent },
  { path: 'parceiros', component: ParceirosComponent },
  { path: 'sobre', component: SobreComponent },
  { path: 'contato', component: ContatoComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
