<div id="parceiros" class="common">
  <section id="banner">
    <div class="item">
      <div class="verticalize">
        <div class="wrapper">
          <h2 style="font-weight: 300">Parceiros</h2>
          <p>Acreditamos que as melhoras histórias são construídas através de parceiras.</p>
        </div>
      </div>
    </div>
  </section>

  <section id="content">
    <div class="wrapper">
      <div class="title">
        <h3>Nossos parceiros são fundamentais para implementar as soluções de sucesso, hoje e amanhã.</h3>
        <p>Seja uma agência digital, uma integradora ou um publisher de aplicativos: não importa. Além de ter acesso ao mais avançado treinamento em tecnologias de proximidade e acesso ilimitado à todas as novidades e roadmap, nossos parceiros trazem expertise complementar que permite construir e entregar os cases de sucesso mais avançados do mercado.</p>
      </div>
      <ul>
        <li><a href="https://www.ibm.com/br-pt/" target="_blank"><img src="/assets/img/partners/ibm.png" alt="IBM Brasil"/></a></li>
        <li><a href="https://unacast.com" target="_blank"><img src="/assets/img/partners/unacast.png" alt="Unacast"/></a></li>
        <li><a href="https://accent-systems.com" target="_blank"><img src="/assets/img/partners/accentsystems.png" alt="Accent Systems"/></a></li>
        <li><a href="http://www.advanta.com.br" target="_blank"><img src="/assets/img/partners/advanta.png" alt="Advanta"/></a></li>
        <li><a href="http://think4.com.br" target="_blank"><img src="/assets/img/partners/think4.png" alt="Think4"/></a></li>
        <li><a href="http://www.xds.com.br" target="_blank"><img src="/assets/img/partners/xds.png" alt="XDS"/></a></li>
        <li><a href="http://handmade9.com" target="_blank"><img src="/assets/img/partners/handmade9.png" alt="XDS"/></a></li>

      </ul>
    </div>
  </section>
</div>
