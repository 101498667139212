<div id="sobre" class="common">
  <section id="banner">
    <div class="item">
      <div class="verticalize">
        <div class="wrapper">
          <h2>Sobre</h2>
        </div>
      </div>
    </div>
  </section>

  <section id="content">
    <div class="wrapper">
      <div class="title">
        <h3>Sobre Nós</h3>
        <p>Somos engenheiros, arquitetos de informação, designers e empreendedores cujo core sempre é absoluta qualidade, inovação e geração de valor. No entanto acreditamos que quem possa melhor falar sobre nós são justamente nossos clientes e parceiros.</p>
      </div>
    </div>
  </section>
</div>
