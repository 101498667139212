import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { User } from '../home/home.component';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {

  constructor(private http: HttpClient) { }

  sendContact(data: User): Observable<any>{
    return this.http.post('contact', data, {
      responseType: 'text'
    });
  }

  sendWhitepaper(data: User): Observable<any>{
    return this.http.post('whitepaper', data, {
      responseType: 'text'
    });
  }
}
