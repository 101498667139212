import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carreira',
  templateUrl: './carreira.component.html',
  styleUrls: ['./carreira.component.css']
})
export class CarreiraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
