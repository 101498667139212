<div id="solucoes" class="common">
  <section id="banner">
    <div class="item">
      <div class="verticalize">
        <div class="wrapper">
          <h2>Soluções</h2>
          <p>Nossas tecnologias permitem a construção de inteligência e interação para os mais diversos setores econômicos.</p>
        </div>
      </div>
    </div>
  </section>

  <section id="content">
    <div class="wrapper">
      <div class="title">
        <h3>Marcas são Definidas pelas Experiências do Consumidor</h3>
        <p>O consumidor de hoje exige que marcas se comuniquem com ele de maneira personalizada. Ciente disso, profissionais de marketing buscam prover experiências memoráveis e diretamente relevantes as suas necessidades. No entanto, para criar esse tipo de experiência para o cliente, é necessária a coleta e análise de uma infinidade de dados e de sinais de intenção em tempo real.</p>
        <br/>
        <p>As tecnologias da AUREA vão muito além de segmentação genérica, permitindo a construção rápida de soluções avançadas, individuais e inteligentes.</p>
      </div>
      <div class="trio">
        <div class="item item-mobile">
          <span class="um"></span>
          <h4>
            Atribuição Online-Offline
          </h4>
          <p>Tecnologias inteligentes de contexto e localização permitem a construção de soluções completas omni-channel, integrando a inteligência obtida através de canais digitais e de pagamento com ambientes físicos. Uma cliente pode, por exemplo, ter analisado um calçado em um e-commerce e essa informação é disponibilizada ao ambiente de loja para ser utilizada durante a venda.</p>
        </div>
        <div class="item item-mobile">
          <span class="dois"></span>
          <h4>Retargeting</h4>
          <p>Geolocalização e beacons atuam como cookies do mundo "offline". Desse modo, é possível por exemplo identificar clientes que permanecem por mais tempo em um setor específico de um varejista (e.g. setor de dietéticos) e impactá-los através de mídias complementares, como redes sociais.</p>
        </div>
        <div class="item item-mobile">
          <span class="tres"></span>
          <h4>Inteligência Comunicação Personalizada</h4>
          <p>Enviar em tempo real comunicação personalizada (como descontos e promoções) baseadas no histórico de compras e contexto de localização permite a marcas criarem experiências únicas de relacionamento com seus clientes.</p>
        </div>
      </div>
    </div>
  </section>
</div>
